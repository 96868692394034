import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Select } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "select"
    }}>{`Select`}</h1>
    <p>{`Styled HTML select component.`}</p>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<Select>\n  <option value=\"value1\">Option with long value</option>\n  <option value=\"value2\">Option 2</option>\n  <option value=\"value3\">Option 3</option>\n</Select>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select mdxType="Select">
    <option value="value1">Option with long value</option>
    <option value="value2">Option 2</option>
    <option value="value3">Option 3</option>
  </Select>
    </Playground>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <Playground __position={1} __code={'<Select disabled>\n  <option value=\"value1\">Option 1</option>\n  <option value=\"value2\">Option 2</option>\n  <option value=\"value3\">Option 3</option>\n</Select>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select disabled mdxType="Select">
    <option value="value1">Option 1</option>
    <option value="value2">Option 2</option>
    <option value="value3">Option 3</option>
  </Select>
    </Playground>
    <h2 {...{
      "id": "alternative"
    }}>{`Alternative`}</h2>
    <p>{`In flows with only a few fields you might use the prop `}<inlineCode parentName="p">{`alternative`}</inlineCode>{` to get a gray select menues that stands out more.`}</p>
    <Playground __position={2} __code={'<Select alternative>\n  <option value=\"value1\">Option 1</option>\n  <option value=\"value2\">Option 2</option>\n  <option value=\"value3\">Option 3</option>\n</Select>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Select,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Select alternative mdxType="Select">
    <option value="value1">Option 1</option>
    <option value="value2">Option 2</option>
    <option value="value3">Option 3</option>
  </Select>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Select} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      